import { ParsedUrlQuery } from "querystring";
import { QUERY_KEY } from "./QueryKey";
import { StringQuery, DictionaryQuery } from "./QueryType";
import { BOOLEAN_TRUE } from "./QueryValue";
import { CategoryConditionHelper } from "@/pages/list/Condition/CategoryCondition";
import { ConstructionConditionHelper } from "@/pages/list/Condition/ConstructionCondition";
import { FloorPlanConditionHelper } from "@/pages/list/Condition/FloorPlanCondition";
import { Article } from "~/types/ArticleType";
import RegionConditionType from "~/types/regionCondition/RegionConditionType";
import ShikugunConditionType from "~/types/regionCondition/ShikugunConditionType";
import ShikugunGroupConditionType from "~/types/regionCondition/ShikugunGroupConditionType";
import TodofukenConditionType from "~/types/regionCondition/TodofukenConditionType";
import LineConditionType from "~/types/routeCondition/LineConditionType";
import RouteConditionType from "~/types/routeCondition/RouteConditionType";
import StationConditionType from "~/types/routeCondition/StationConditionType";
import { valueOf } from "~/utils/typeUtil";

/**
 * リストページの検索条件をまとめたクラス
 */
export default class Conditions {
  /** 沿線・駅条件 */
  routeCondition: RouteConditionType;
  /** 都道府県・市区郡条件 */
  regionCondition: RegionConditionType;
  /** キーワード条件 */
  keywordCondition: valueOf<StringQuery>;
  /** 最低初期トク条件 */
  lowerInitialCostScoreCondition: valueOf<StringQuery>;
  /** 最低家賃条件 */
  lowerRentCondition: valueOf<StringQuery>;
  /** 最高家賃条件 */
  upperRentCondition: valueOf<StringQuery>;
  /** 家賃の管理費込み条件 */
  includeAdministrationFeeCondition: boolean;
  /** 徒歩分条件 */
  upperFootCondition: valueOf<StringQuery>;
  /** 最低面積条件 */
  lowerAreaCondition: valueOf<StringQuery>;
  /** 最高面積条件 */
  upperAreaCondition: valueOf<StringQuery>;
  /** 最低築年条件 */
  lowerBuildingAgeCondition: valueOf<StringQuery>;
  /** 最高築年条件 */
  upperBuildingAgeCondition: valueOf<StringQuery>;
  /** 間取り条件 */
  floorPlanCondition: valueOf<DictionaryQuery>;
  /** 構造条件 */
  constructionCondition: valueOf<DictionaryQuery>;
  /** こだわり条件 */
  categoriesCondition: valueOf<DictionaryQuery>;
  /** 並び替え条件 */
  orderCondition: valueOf<StringQuery>;
  /** ページ条件 */
  pageCondition: valueOf<StringQuery>;
  /** 管理会社名の検索条件 */
  propertyAgentNameCondition: valueOf<StringQuery>;

  /** クエリで指定されているarticle */
  selectedArticle: Article | undefined;

  /** クエリで指定されている沿線 */
  selectedLines: LineConditionType[];
  /** クエリで指定されている駅 */
  selectedStations: StationConditionType[];
  /** クエリで指定されている都道府県 */
  selectedTodofukens: TodofukenConditionType[];
  /** クエリで指定されている市区郡カテゴリ */
  selectedShikugunGroups: ShikugunGroupConditionType[];
  /** クエリで指定されている市区郡 */
  selectedShikuguns: ShikugunConditionType[];

  /** string型のURLクエリ */
  private stringQuery: StringQuery;
  /** 辞書型のURLクエリ */
  private dictionaryQuery: DictionaryQuery;
  /** articleに紐づくstring型のクエリ */
  private articleStringQuery: StringQuery;
  /** articleに紐づく辞書型のURLクエリ */
  private articleDictionaryQuery: DictionaryQuery;

  constructor(
    conditions: {
      routeCondition: RouteConditionType;
      regionCondition: RegionConditionType;
      keywordCondition: valueOf<StringQuery>;
      lowerInitialCostScoreCondition: valueOf<StringQuery>;
      lowerRentCondition: valueOf<StringQuery>;
      upperRentCondition: valueOf<StringQuery>;
      includeAdministrationFeeCondition: boolean;
      upperFootCondition: valueOf<StringQuery>;
      lowerAreaCondition: valueOf<StringQuery>;
      upperAreaCondition: valueOf<StringQuery>;
      floorPlanCondition: valueOf<DictionaryQuery>;
      lowerBuildingAgeCondition: valueOf<StringQuery>;
      upperBuildingAgeCondition: valueOf<StringQuery>;
      constructionCondition: valueOf<DictionaryQuery>;
      categoriesCondition: valueOf<DictionaryQuery>;
      orderCondition: valueOf<StringQuery>;
      pageCondition: valueOf<StringQuery>;
      propertyAgentNameCondition: valueOf<StringQuery>;
    },
    parsedQueries: {
      stringQuery: StringQuery;
      dictionaryQuery: DictionaryQuery;
      articleStringQuery: StringQuery;
      articleDictionaryQuery: DictionaryQuery;
    },
  ) {
    this.routeCondition = conditions.routeCondition;
    this.regionCondition = conditions.regionCondition;
    this.keywordCondition = conditions.keywordCondition;
    this.lowerInitialCostScoreCondition = conditions.lowerInitialCostScoreCondition;
    this.lowerRentCondition = conditions.lowerRentCondition;
    this.upperRentCondition = conditions.upperRentCondition;
    this.upperFootCondition = conditions.upperFootCondition;
    this.includeAdministrationFeeCondition = conditions.includeAdministrationFeeCondition;
    this.lowerAreaCondition = conditions.lowerAreaCondition;
    this.upperAreaCondition = conditions.upperAreaCondition;
    this.floorPlanCondition = conditions.floorPlanCondition;
    this.lowerBuildingAgeCondition = conditions.lowerBuildingAgeCondition;
    this.upperBuildingAgeCondition = conditions.upperBuildingAgeCondition;
    this.constructionCondition = conditions.constructionCondition;
    this.categoriesCondition = conditions.categoriesCondition;
    this.orderCondition = conditions.orderCondition;
    this.pageCondition = conditions.pageCondition;
    this.propertyAgentNameCondition = conditions.propertyAgentNameCondition;

    this.selectedArticle = Article.find(parsedQueries.stringQuery[QUERY_KEY.ARTICLE]);

    this.selectedLines = conditions.routeCondition.getCheckedLines();
    this.selectedStations = conditions.routeCondition.getCheckedStations(true);
    this.selectedTodofukens = conditions.regionCondition.getCheckedTodofukens();
    this.selectedShikuguns = conditions.regionCondition.getCheckedShikuguns({
      excludesTodofukenSelected: true,
      excludesShikugunGroupSelected: true,
    });
    this.selectedShikugunGroups = conditions.regionCondition.getCheckedShikugunGroups({
      excludesTodofukenSelected: true,
    });

    this.stringQuery = parsedQueries.stringQuery;
    this.dictionaryQuery = parsedQueries.dictionaryQuery;
    this.articleStringQuery = parsedQueries.articleStringQuery;
    this.articleDictionaryQuery = parsedQueries.articleDictionaryQuery;
  }

  /** エリア条件の設定数 */
  get selectedAreaConditionLength(): number {
    return (
      this.selectedLines.length +
      this.selectedStations.length +
      this.selectedTodofukens.length +
      this.selectedShikuguns.length +
      this.selectedShikugunGroups.length
    );
  }

  /** エリア系クエリの数 */
  get queryAreaCount(): number {
    return (
      Number(!!this.dictionaryQuery[QUERY_KEY.STATION]) +
      Number(!!this.dictionaryQuery[QUERY_KEY.LINE]) +
      Number(!!this.dictionaryQuery[QUERY_KEY.SHIKUGUN]) +
      Number(!!this.dictionaryQuery[QUERY_KEY.SHIKUGUN_GROUP]) +
      Number(!!this.dictionaryQuery[QUERY_KEY.TODOFUKEN])
    );
  }

  /** エリア以外の条件設定数（articleの条件は1つと数える） */
  get selectedOtherConditionLength(): number {
    return (
      CategoryConditionHelper.getSelectedCategoryConditions(
        this.dictionaryQuery[QUERY_KEY.CATEGORY] || {},
      )?.length +
      FloorPlanConditionHelper.getSelectedFloorPlans(
        this.dictionaryQuery[QUERY_KEY.FLOOR_PLAN] || {},
      )?.length +
      (this.stringQuery[QUERY_KEY.INITIAL_COST_SCORE] ? 1 : 0) +
      (this.stringQuery[QUERY_KEY.LOWER_RENT] ? 1 : 0) +
      (this.stringQuery[QUERY_KEY.UPPER_RENT] ? 1 : 0) +
      (this.stringQuery[QUERY_KEY.UPPER_FOOT] ? 1 : 0) +
      (this.stringQuery[QUERY_KEY.LOWER_AREA] ? 1 : 0) +
      (this.stringQuery[QUERY_KEY.UPPER_AREA] ? 1 : 0) +
      (this.stringQuery[QUERY_KEY.LOWER_BUILDING_AGE] ? 1 : 0) +
      (this.stringQuery[QUERY_KEY.UPPER_BUILDING_AGE] ? 1 : 0) +
      (ConstructionConditionHelper.getText(this.dictionaryQuery[QUERY_KEY.CONSTRUCTION] || {})
        ? 1
        : 0) +
      (this.stringQuery[QUERY_KEY.KEYWORD] ? 1 : 0) +
      (this.selectedArticle ? 1 : 0)
    );
  }

  /** articleもしくはFloorPlanの条件設定数（index用） */
  get selectedArticleOrFloorPlanConditionLength(): number {
    return (
      FloorPlanConditionHelper.getSelectedFloorPlans(
        this.dictionaryQuery[QUERY_KEY.FLOOR_PLAN] || {},
      )?.length + (this.selectedArticle ? 1 : 0)
    );
  }

  setRouteCondition(routeCondition: RouteConditionType): void {
    this.routeCondition = routeCondition;
  }

  setRegionCondition(regionCondition: RegionConditionType): void {
    this.regionCondition = regionCondition;
  }

  setLowerInitialCostScoreCondition(lowerInitialCostScoreCondition: valueOf<StringQuery>): void {
    this.lowerInitialCostScoreCondition = lowerInitialCostScoreCondition;
  }

  setUpperRentCondition(upperRentCondition: valueOf<StringQuery>): void {
    this.upperRentCondition = upperRentCondition;
  }

  setUpperFootCondition(upperFootCondition: valueOf<StringQuery>): void {
    this.upperFootCondition = upperFootCondition;
  }

  setLowerBuildingAgeCondition(lowerBuildingAgeCondition: valueOf<StringQuery>): void {
    this.lowerBuildingAgeCondition = lowerBuildingAgeCondition;
  }

  setUpperBuildingAgeCondition(upperBuildingAgeCondition: valueOf<StringQuery>): void {
    this.upperBuildingAgeCondition = upperBuildingAgeCondition;
  }

  setCategoriesCondition(categoriesCondition: valueOf<DictionaryQuery>): void {
    this.categoriesCondition = categoriesCondition;
  }

  removeFootCondition(): void {
    this.setUpperFootCondition("");
  }

  removeBuildingAgeConditions(): void {
    this.setLowerBuildingAgeCondition("");
    this.setUpperBuildingAgeCondition("");
  }

  removeCategoryForSimilarConditions(): void {
    this.setLowerInitialCostScoreCondition("");

    const categoriesCondition = { ...this.categoriesCondition };
    delete categoriesCondition["0"]; // 敷金なし
    delete categoriesCondition["1"]; // 礼金なし
    delete categoriesCondition["5"]; // デザイナーズ
    delete categoriesCondition["9"]; // リノベーション
    delete categoriesCondition["25"]; // 仲介手数料無料
    delete categoriesCondition["28"]; // フリーレント付き
    this.setCategoriesCondition(categoriesCondition);
  }

  static fromQuery(query: ParsedUrlQuery): Conditions {
    // 通常のクエリのパース
    const classifiedQuery = this.classifyQuery(query);
    const stringQuery = classifiedQuery.stringQuery;
    const dictionaryQuery = classifiedQuery.dictionaryQuery;

    // articleのクエリのパース
    const selectedArticle = Article.find(String(query.a_id));
    const articleClassfiedQuery = this.classifyQuery(selectedArticle?.search_query || {});
    const articleStringQuery = articleClassfiedQuery.stringQuery;
    const articleDictionaryQuery = articleClassfiedQuery.dictionaryQuery;

    // エリア条件の抽出
    const routeCondition = new RouteConditionType(
      dictionaryQuery[QUERY_KEY.LINE] || {},
      dictionaryQuery[QUERY_KEY.STATION] || {},
    );
    const regionCondition = new RegionConditionType(
      dictionaryQuery[QUERY_KEY.TODOFUKEN] || {},
      dictionaryQuery[QUERY_KEY.SHIKUGUN] || {},
      dictionaryQuery[QUERY_KEY.SHIKUGUN_GROUP] || {},
    );

    // エリア以外の条件の抽出
    const keywordCondition =
      articleStringQuery[QUERY_KEY.KEYWORD] ?? stringQuery[QUERY_KEY.KEYWORD] ?? "";
    const lowerInitialCostScoreCondition =
      articleStringQuery[QUERY_KEY.INITIAL_COST_SCORE] ??
      stringQuery[QUERY_KEY.INITIAL_COST_SCORE] ??
      "";
    const lowerRentCondition =
      articleStringQuery[QUERY_KEY.LOWER_RENT] ?? stringQuery[QUERY_KEY.LOWER_RENT] ?? "";
    const upperRentCondition =
      articleStringQuery[QUERY_KEY.UPPER_RENT] ?? stringQuery[QUERY_KEY.UPPER_RENT] ?? "";
    const includeAdministrationFeeCondition =
      stringQuery[QUERY_KEY.INCLUDE_ADMIN_FREE] === BOOLEAN_TRUE;
    const upperFootCondition =
      articleStringQuery[QUERY_KEY.UPPER_FOOT] ?? stringQuery[QUERY_KEY.UPPER_FOOT] ?? "";
    const lowerAreaCondition =
      articleStringQuery[QUERY_KEY.LOWER_AREA] ?? stringQuery[QUERY_KEY.LOWER_AREA] ?? "";
    const upperAreaCondition =
      articleStringQuery[QUERY_KEY.UPPER_AREA] ?? stringQuery[QUERY_KEY.UPPER_AREA] ?? "";
    const lowerBuildingAgeCondition =
      articleStringQuery[QUERY_KEY.LOWER_BUILDING_AGE] ??
      stringQuery[QUERY_KEY.LOWER_BUILDING_AGE] ??
      "";
    const upperBuildingAgeCondition =
      articleStringQuery[QUERY_KEY.UPPER_BUILDING_AGE] ??
      stringQuery[QUERY_KEY.UPPER_BUILDING_AGE] ??
      "";
    const floorPlanCondition =
      {
        ...dictionaryQuery[QUERY_KEY.FLOOR_PLAN],
        ...articleDictionaryQuery[QUERY_KEY.FLOOR_PLAN],
      } || {};
    const constructionCondition =
      {
        ...dictionaryQuery[QUERY_KEY.CONSTRUCTION],
        ...articleDictionaryQuery[QUERY_KEY.CONSTRUCTION],
      } || {};
    const categoriesCondition =
      {
        ...dictionaryQuery[QUERY_KEY.CATEGORY],
        ...articleDictionaryQuery[QUERY_KEY.CATEGORY],
      } || {};
    const orderCondition =
      articleStringQuery[QUERY_KEY.ORDER] || stringQuery[QUERY_KEY.ORDER] || "recommend";
    const pageCondition = stringQuery[QUERY_KEY.PAGE] || "1";
    const propertyAgentNameCondition = stringQuery[QUERY_KEY.PROPERTY_AGENT_NAME] || "";

    return new this(
      {
        routeCondition,
        regionCondition,
        keywordCondition,
        lowerInitialCostScoreCondition,
        lowerRentCondition,
        upperRentCondition,
        includeAdministrationFeeCondition,
        upperFootCondition,
        lowerAreaCondition,
        upperAreaCondition,
        floorPlanCondition,
        lowerBuildingAgeCondition,
        upperBuildingAgeCondition,
        constructionCondition,
        categoriesCondition,
        orderCondition,
        pageCondition,
        propertyAgentNameCondition,
      },
      {
        stringQuery,
        dictionaryQuery,
        articleStringQuery,
        articleDictionaryQuery,
      },
    );
  }

  /** URLクエリを文字列型と辞書型に分類する */
  private static classifyQuery(query: ParsedUrlQuery) {
    const stringQuery: StringQuery = {};
    const dictionaryQuery: DictionaryQuery = {};

    for (const [key, value] of Object.entries(query)) {
      // dから始まる場合は辞書型の引数とみなす
      // ただし、フリーテキストが入ってくるk(キーワード)とo(並び順)は、偶然の一致を防ぐため除外する
      if (!!value) {
        if (value.slice(0, 1) === "d" && key !== QUERY_KEY.KEYWORD && key !== QUERY_KEY.ORDER) {
          const tmp: Record<string, boolean> = {};
          String(value)
            .slice(2)
            .split("-")
            .forEach((i) => {
              if (i) tmp[i] = true;
            });
          dictionaryQuery[key] = tmp;
        } else {
          stringQuery[key] = String(value);
        }
      }
    }

    return { stringQuery, dictionaryQuery };
  }
}
