import { Dispatch, SetStateAction } from "react";
import styles from "~/styles/pages/list/Search.module.scss";

class FloorPlanConditionType {
  constructor(
    private readonly _id: string,
    private readonly _display_name: string,
    private readonly _seo_title: string | undefined,
  ) {}

  get id(): string {
    return this._id;
  }

  get display_name(): string {
    return this._display_name;
  }

  get seo_title(): string {
    return this._seo_title ?? this._display_name;
  }
}

export const floorPlanConditions = [
  { id: "1_r", display_name: "ワンルーム", seo_title: "1R(ワンルーム)" },
  { id: "1_k", display_name: "1K", seo_title: undefined },
  { id: "1_dk", display_name: "1DK", seo_title: undefined },
  { id: "1_ldk", display_name: "1LDK", seo_title: undefined },
  { id: "2_k", display_name: "2K", seo_title: undefined },
  { id: "2_dk", display_name: "2DK", seo_title: undefined },
  { id: "2_ldk", display_name: "2LDK", seo_title: undefined },
  { id: "3_k", display_name: "3K", seo_title: undefined },
  { id: "3_dk", display_name: "3DK", seo_title: undefined },
  { id: "3_ldk", display_name: "3LDK", seo_title: undefined },
  { id: "4_more", display_name: "4部屋以上", seo_title: "4K/4DK/4LDK(4部屋)以上" },
].map((source) => new FloorPlanConditionType(source.id, source.display_name, source.seo_title));

export const FloorPlanConditionHelper = {
  getText: (floorPlan: { [code: string]: boolean }): string =>
    FloorPlanConditionHelper.getSelectedFloorPlans(floorPlan)
      .map((floorPlan) => floorPlan.display_name)
      .join("または"),
  getTitle: (floorPlan: { [code: string]: boolean }): string => {
    const primaryCondition = FloorPlanConditionHelper.getSelectedFloorPlans(floorPlan)[0];
    return primaryCondition?.seo_title ?? "";
  },
  getSelectedFloorPlans: (floorPlan: { [code: string]: boolean }): FloorPlanConditionType[] => {
    return floorPlanConditions.filter((floorPlanCondition) => floorPlan[floorPlanCondition.id]);
  },
};

type FloorPlanConditionProps = {
  floorPlan: { [code: string]: boolean };
  setFloorPlan: Dispatch<
    SetStateAction<{
      [code: string]: boolean;
    }>
  >;
};

const FloorPlanCondition = ({ floorPlan, setFloorPlan }: FloorPlanConditionProps): JSX.Element => {
  const name = "floorPlan";

  return (
    <>
      {floorPlanConditions.map((floorPlanCondition) => {
        const key = floorPlanCondition.id;
        const value = floorPlanCondition.display_name;
        return (
          <span key={`${name}_${key}`} className={styles.checklistConditionItem}>
            <input
              type="checkbox"
              name={`${name}_${key}`}
              id={`${name}_${key}`}
              value={`${name}_${key}`}
              checked={Boolean(floorPlan[key])}
              onChange={() => {
                const tmp = floorPlan;
                tmp[key] = !floorPlan[key];
                setFloorPlan((prev) => ({ ...prev, [key]: tmp[key] }));
              }}
            />
            <label htmlFor={`${name}_${key}`}>{value}</label>
          </span>
        );
      })}
    </>
  );
};

export default FloorPlanCondition;
