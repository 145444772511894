import { Dispatch, SetStateAction } from "react";
import styles from "~/styles/pages/list/Search.module.scss";

type ConstructionOptions = {
  [id: string]: string;
};

export const ConstructionConditionHelper = {
  options: {
    1: "鉄筋系",
    2: "鉄骨系",
    3: "木造",
    4: "その他の構造",
  } as ConstructionOptions,
  getText: (construction: { [id: string]: boolean }): string =>
    construction &&
    Object.keys(construction).reduce(
      (pre, cur) =>
        construction[cur]
          ? pre
            ? pre + "または" + ConstructionConditionHelper.options[cur]
            : ConstructionConditionHelper.options[cur]
          : pre,
      "",
    ),
};

type ConstructionConditionProps = {
  construction: { [id: string]: boolean };
  setConstruction: Dispatch<
    SetStateAction<{
      [id: string]: boolean;
    }>
  >;
};

const ConstructionCondition = ({
  construction,
  setConstruction,
}: ConstructionConditionProps): JSX.Element => {
  const name = "construction";

  return (
    <>
      {Object.keys(ConstructionConditionHelper.options).map((key) => {
        const value = ConstructionConditionHelper.options[key];
        return (
          <span key={`${name}_${key}`} className={styles.checklistConditionItem}>
            <input
              type="checkbox"
              name={`${name}_${key}`}
              id={`${name}_${key}`}
              value={`${name}_${key}`}
              checked={Boolean(construction[key])}
              onChange={() => {
                const tmp = construction;
                tmp[key] = !construction[key];
                setConstruction((prev) => ({ ...prev, [key]: tmp[key] }));
              }}
            />
            <label htmlFor={`${name}_${key}`}>{value}</label>
          </span>
        );
      })}
    </>
  );
};

export default ConstructionCondition;
