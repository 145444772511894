import { Dispatch, SetStateAction } from "react";
import styles from "~/styles/pages/list/Search.module.scss";
import {
  CategoryCondition as CategoryConditionType,
  categoryConditions,
} from "~/types/CategoryConditionType";

export const CategoryConditionHelper = {
  getText: (categories: { [id: number]: boolean }): string =>
    CategoryConditionHelper.getSelectedCategoryConditions(categories)
      .map((categoryCondition) => categoryCondition.display_name)
      .join(","),
  getTitle: (categories: { [id: number]: boolean }): string => {
    return CategoryConditionHelper.getSelectedCategoryConditions(categories)[0]?.seo_title ?? "";
  },
  getSelectedCategoryConditions: (categories: {
    [id: number]: boolean;
  }): CategoryConditionType[] => {
    return categoryConditions
      .map((categoryCondition) => {
        return categories[categoryCondition.id] ? categoryCondition : undefined;
      })
      .filter((v): v is CategoryConditionType => !!v);
  },
};

type CategoryConditionProps = {
  categories: { [id: number]: boolean };
  setCategories: Dispatch<
    SetStateAction<{
      [categories: string]: boolean;
    }>
  >;
  categoryType: string;
};

const CategoryCondition = ({
  categories,
  setCategories,
  categoryType,
}: CategoryConditionProps): JSX.Element => {
  const name = "category";

  return (
    <>
      {categoryConditions
        .sort((a, b) => a["order"] - b["order"])
        .map((category) => {
          /* 表示しない設定のカテゴリを除外する */
          if (!category.is_display) return;

          /* 内部メンバー用の条件は、選択されていない場合は除外 */
          /* 内部メンバー用の条件は、カテゴリIDが1000以上 */
          if (category.id >= 1000 && !categories[category.id]) return;

          /* カテゴリタイプが指定されている場合 */
          if (categoryType) {
            /* カテゴリタイプが"こだわり条件"なら、kodawari_flgがあるカテゴリ以外は除外 */
            if (categoryType === "こだわり条件" && !category.kodawari_flg) return;
            /* カテゴリタイプが"こだわり条件"でなければ、そのカテゴリ以外は除外 */
            if (categoryType !== "こだわり条件" && categoryType !== category.category_type) return;
          }

          return (
            <span key={`${name}_${category.id}`} className={styles.checklistConditionItem}>
              <input
                type="checkbox"
                name={`${name}_${category.id}`}
                id={`${name}_${category.id}`}
                value={`${name}_${category.id}`}
                checked={Boolean(categories[category.id])}
                onChange={() => {
                  const tmp = categories;
                  tmp[category.id] = !tmp[category.id];
                  setCategories((prev) => ({ ...prev, [category.id]: tmp[category.id] }));
                }}
              />
              <label htmlFor={`${name}_${category.id}`}>{category.display_name}</label>
            </span>
          );
        })}
    </>
  );
};

export default CategoryCondition;
